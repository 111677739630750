import { startsWith } from './utils.js'

export const CIPHER_AWS_HOSTNAME = 'app.aws.cipher.ai'
export const CIPHER31_PREV_HOSTNAME = 'app31.cipher.ai'
export const BM_PREV_HOSTNAME = 'bm.cipher.ai'

export const CIPHER_HOSTNAME = 'https://app.cipher.ai'

const CIPHER_VERSION_COOKIE_NAME = 'cipher_version'
const DEV_CIPHER_VERSION_COOKIE_NAME = 'cipher_version_dev'

const CIPHER_OLD_VERSION = '3.1'
//const CIPHER_NEW_VERSION = '4.0'
export const CIPHER_OTHER_VERSION = CIPHER_OLD_VERSION

const CIPHER_OLD_HOSTNAME = 'app.cipher.ai'
const CIPHER_NEW_HOSTNAME = 'app4.cipher.ai'
const DEV_CIPHER_OLD_HOSTNAME = 'app-dev.aws.cipher.ai'
const DEV_CIPHER_NEW_HOSTNAME = 'app3-dev.aws.cipher.ai'

const CIPHER_OTHER_HOSTNAME = 'https://' + CIPHER_OLD_HOSTNAME
const DEV_CIPHER_OTHER_HOSTNAME = 'https://' + DEV_CIPHER_OLD_HOSTNAME

function is_dev() {
  const host = window.location.host
  return startsWith(host, DEV_CIPHER_NEW_HOSTNAME) || startsWith(host, DEV_CIPHER_OLD_HOSTNAME)
}

function is_prod() {
  const host = window.location.host
  return startsWith(host, CIPHER_NEW_HOSTNAME) || startsWith(host, CIPHER_OLD_HOSTNAME)
}

export function is_should_redirect_to_other_cipher(cipher_version) {
  return (is_prod() || is_dev()) && (cipher_version != null) && (cipher_version === CIPHER_OTHER_VERSION)
}

export function get_cipher_version_cookie_name() {
  return is_dev() ? DEV_CIPHER_VERSION_COOKIE_NAME : CIPHER_VERSION_COOKIE_NAME
}

export function get_cipher_version_cookie_domain() {
  return is_dev() ? '.test.aistemos.com' : '.cipher.ai'
}

export function get_cipher_other_hostname() {
  return is_dev() ? DEV_CIPHER_OTHER_HOSTNAME : CIPHER_OTHER_HOSTNAME
}

export function should_enable_switch() {
  return (is_prod() || is_dev())
}
